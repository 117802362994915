import type { BookRenderData } from '@/components/common/Book';

import type { GroupSecitonsExtrasData, GroupSectionsSlotData } from './types';

export const groupSections = {
  convertToRenderData: ({
    slot,
    extras,
  }: {
    slot?: GroupSectionsSlotData;
    extras?: GroupSecitonsExtrasData;
  }): BookRenderData => ({
    bookId: slot?.book_id,
    title: slot?.title,
    cover: {
      badges: {
        image: extras?.badges?.map(badge => ({
          alt: badge.badge_text,
          color: badge.badge_background,
          images: badge.badge_urls,
        })),

        schedule: extras?.schedule?.text || undefined,
      },

      isAdultOnly: slot?.adults_only,

      thumbnail: slot?.cover,

      isPreExclusive: extras?.pre_exclusive?.is_pre_exclusive,
    },
    metadata: {
      title: {
        showTitle: true,
      },

      authors: slot?.authors,

      starRate: {
        score: slot?.ratings?.rating_average,
        count: slot?.ratings?.total_count,
      },

      extra: {
        series: {
          isEntireSeriesPreExclusive: extras?.pre_exclusive?.is_entire_series_pre_exclusive ?? false,
          isNewEpisodePreExclusive: extras?.pre_exclusive?.is_new_episode_pre_exclusive ?? false,
        },
        isSeries: false,
      },
    },
  }),
};
