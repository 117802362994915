import { useEffect as T } from "react";
const E = "referralCode", u = "ridi-referral-event", B = "ridi_app_theme", G = "ridi_nav", W = "ridi_auth", q = "adult_exclude", Q = "adult_include", X = "login_time", J = "deauth_watchdog_servertoken", Z = "use_grid", ee = "from_account_modify", te = "fingerprint", re = "add_pwa_requested", ne = "review-tab", ie = "FIG", oe = "ridi-at", ce = "ridi-rt", ae = "ridi-al", se = "user_device_type", Ee = "ridi_app", _e = "ridi_app_version", de = "ridi-ffid", ue = "PHPSESSID", le = "logout_time", Ie = "__books__", fe = "server_metrics", Pe = "ruid", Re = "ridi-referral-event", pe = "app_instance_id", De = "app_ga_session_id", he = "firebase_app_id", me = "appsflyer_id", Ae = "inpay", Se = "_rdt_info", Te = "iPod", Ce = "iPhone", ge = "iPad", Oe = "Android", ye = "AndroidTab", ve = "PC", we = "WindowsPhone", xe = "WindowsCE", Ye = "EtcMobileDevice", Ne = "PAPER", be = "PAPER Lite", Le = "PAPER PRO", Ue = "RIDIPAPER", Ke = "RIDIPAPER 4", C = "ridi", g = "ridiplaystore", O = "ridionestore", y = "ridiappstore", v = "saudi", ke = [
  C,
  g,
  O,
  y,
  v
];
/*! js-cookie v3.0.5 | MIT */
function f(e) {
  for (var r = 1; r < arguments.length; r++) {
    var t = arguments[r];
    for (var c in t)
      e[c] = t[c];
  }
  return e;
}
var w = {
  read: function(e) {
    return e[0] === '"' && (e = e.slice(1, -1)), e.replace(/(%[\dA-F]{2})+/gi, decodeURIComponent);
  },
  write: function(e) {
    return encodeURIComponent(e).replace(
      /%(2[346BF]|3[AC-F]|40|5[BDE]|60|7[BCD])/g,
      decodeURIComponent
    );
  }
};
function R(e, r) {
  function t(o, n, i) {
    if (!(typeof document > "u")) {
      i = f({}, r, i), typeof i.expires == "number" && (i.expires = new Date(Date.now() + i.expires * 864e5)), i.expires && (i.expires = i.expires.toUTCString()), o = encodeURIComponent(o).replace(/%(2[346B]|5E|60|7C)/g, decodeURIComponent).replace(/[()]/g, escape);
      var s = "";
      for (var a in i)
        i[a] && (s += "; " + a, i[a] !== !0 && (s += "=" + i[a].split(";")[0]));
      return document.cookie = o + "=" + e.write(n, o) + s;
    }
  }
  function c(o) {
    if (!(typeof document > "u" || arguments.length && !o)) {
      for (var n = document.cookie ? document.cookie.split("; ") : [], i = {}, s = 0; s < n.length; s++) {
        var a = n[s].split("="), I = a.slice(1).join("=");
        try {
          var d = decodeURIComponent(a[0]);
          if (i[d] = e.read(I, d), o === d)
            break;
        } catch {
        }
      }
      return o ? i[o] : i;
    }
  }
  return Object.create(
    {
      set: t,
      get: c,
      remove: function(o, n) {
        t(
          o,
          "",
          f({}, n, {
            expires: -1
          })
        );
      },
      withAttributes: function(o) {
        return R(this.converter, f({}, this.attributes, o));
      },
      withConverter: function(o) {
        return R(f({}, this.converter, o), this.attributes);
      }
    },
    {
      attributes: { value: Object.freeze(r) },
      converter: { value: Object.freeze(e) }
    }
  );
}
var l = R(w, { path: "/" });
const x = /^https:\/\/((next|view|preflight)\.local\.)?ridibooks\.com.*$/;
async function Y({ referralEventData: e }) {
  if (!globalThis.location)
    return;
  const r = new URL(globalThis.location.href), t = Object.fromEntries(r.searchParams.entries());
  let c = !1;
  if (e) {
    const o = new Date(e.eventStartAt), n = new Date(e.eventEndAt), i = o.getTime(), s = n.getTime();
    if (!Number.isNaN(i) && !Number.isNaN(s)) {
      const a = Date.now();
      c = !(i <= a && a <= s);
    }
  }
  if (c) {
    if (t[E] || l.get(u)) {
      const { [E]: o, ...n } = t;
      l.remove(u, {
        domain: ".ridibooks.com",
        path: "/",
        secure: !0
      });
      const i = `${r.pathname}?${new URLSearchParams(n).toString()}`;
      globalThis.history.replaceState({}, "", i);
    }
    return;
  }
  if (x.test(globalThis.location.href) && (E in t && t[E] && l.set(u, t[E], {
    path: "/",
    domain: ".ridibooks.com",
    secure: !0
  }), l.get(u) && !(E in t))) {
    const o = `${r.pathname}?${new URLSearchParams({
      ...t,
      [E]: l.get(u)
    }).toString()}`;
    globalThis.history.replaceState({}, "", o);
  }
}
const Ve = (e, r) => {
  T(() => {
    Y(e);
  }, [e.referralEventData, ...r]);
}, N = /* @__PURE__ */ new Map([
  ["author", "저자"],
  ["illustrator", "그림"],
  ["story_writer", "글"],
  ["original_author", "원작"],
  ["translator", "번역"],
  ["author_photo", "사진"],
  ["planner", "기획"],
  ["bibliographical_introduction", "해제"],
  ["compiler", "엮음"],
  ["commentator", "해설"],
  ["editor", "편집"],
  ["supervise", "감수"],
  ["performer", "연주자"],
  ["original_illustrator", "원화"]
]), D = Array.from(N.keys()).reduce(
  (e, r, t) => ({ ...e, [r]: t }),
  {}
), Fe = (e) => [...e].sort(
  (r, t) => (D[r.role] ?? 1 / 0) - (D[t.role] ?? 1 / 0)
);
var _ = {};
Object.defineProperty(_, "__esModule", { value: !0 });
_.parse = F;
_.serialize = M;
const b = /^[\u0021-\u003A\u003C\u003E-\u007E]+$/, L = /^[\u0021-\u003A\u003C-\u007E]*$/, U = /^([.]?[a-z0-9]([a-z0-9-]{0,61}[a-z0-9])?)([.][a-z0-9]([a-z0-9-]{0,61}[a-z0-9])?)*$/i, K = /^[\u0020-\u003A\u003D-\u007E]*$/, k = Object.prototype.toString, V = /* @__PURE__ */ (() => {
  const e = function() {
  };
  return e.prototype = /* @__PURE__ */ Object.create(null), e;
})();
function F(e, r) {
  const t = new V(), c = e.length;
  if (c < 2)
    return t;
  const o = (r == null ? void 0 : r.decode) || $;
  let n = 0;
  do {
    const i = e.indexOf("=", n);
    if (i === -1)
      break;
    const s = e.indexOf(";", n), a = s === -1 ? c : s;
    if (i > a) {
      n = e.lastIndexOf(";", i - 1) + 1;
      continue;
    }
    const I = h(e, n, i), d = m(e, i, I), P = e.slice(I, d);
    if (t[P] === void 0) {
      let p = h(e, i + 1, a), A = m(e, a, p);
      const S = o(e.slice(p, A));
      t[P] = S;
    }
    n = a + 1;
  } while (n < c);
  return t;
}
function h(e, r, t) {
  do {
    const c = e.charCodeAt(r);
    if (c !== 32 && c !== 9)
      return r;
  } while (++r < t);
  return t;
}
function m(e, r, t) {
  for (; r > t; ) {
    const c = e.charCodeAt(--r);
    if (c !== 32 && c !== 9)
      return r + 1;
  }
  return t;
}
function M(e, r, t) {
  const c = (t == null ? void 0 : t.encode) || encodeURIComponent;
  if (!b.test(e))
    throw new TypeError(`argument name is invalid: ${e}`);
  const o = c(r);
  if (!L.test(o))
    throw new TypeError(`argument val is invalid: ${r}`);
  let n = e + "=" + o;
  if (!t)
    return n;
  if (t.maxAge !== void 0) {
    if (!Number.isInteger(t.maxAge))
      throw new TypeError(`option maxAge is invalid: ${t.maxAge}`);
    n += "; Max-Age=" + t.maxAge;
  }
  if (t.domain) {
    if (!U.test(t.domain))
      throw new TypeError(`option domain is invalid: ${t.domain}`);
    n += "; Domain=" + t.domain;
  }
  if (t.path) {
    if (!K.test(t.path))
      throw new TypeError(`option path is invalid: ${t.path}`);
    n += "; Path=" + t.path;
  }
  if (t.expires) {
    if (!H(t.expires) || !Number.isFinite(t.expires.valueOf()))
      throw new TypeError(`option expires is invalid: ${t.expires}`);
    n += "; Expires=" + t.expires.toUTCString();
  }
  if (t.httpOnly && (n += "; HttpOnly"), t.secure && (n += "; Secure"), t.partitioned && (n += "; Partitioned"), t.priority)
    switch (typeof t.priority == "string" ? t.priority.toLowerCase() : void 0) {
      case "low":
        n += "; Priority=Low";
        break;
      case "medium":
        n += "; Priority=Medium";
        break;
      case "high":
        n += "; Priority=High";
        break;
      default:
        throw new TypeError(`option priority is invalid: ${t.priority}`);
    }
  if (t.sameSite)
    switch (typeof t.sameSite == "string" ? t.sameSite.toLowerCase() : t.sameSite) {
      case !0:
      case "strict":
        n += "; SameSite=Strict";
        break;
      case "lax":
        n += "; SameSite=Lax";
        break;
      case "none":
        n += "; SameSite=None";
        break;
      default:
        throw new TypeError(`option sameSite is invalid: ${t.sameSite}`);
    }
  return n;
}
function $(e) {
  if (e.indexOf("%") === -1)
    return e;
  try {
    return decodeURIComponent(e);
  } catch {
    return e;
  }
}
function H(e) {
  return k.call(e) === "[object Date]";
}
var j = /* @__PURE__ */ ((e) => (e.BUYER = "buyer", e.ALL = "all", e))(j || {});
const Me = (e) => e ? _.parse(e) : null, $e = (e, r, t) => {
  if (r === void 0) {
    document.cookie = _.serialize(e, "", {
      ...t,
      expires: /* @__PURE__ */ new Date(0)
    });
    return;
  }
  document.cookie = _.serialize(e, r, t);
};
function He(e) {
  return e != null;
}
const je = (e) => e.length <= 3 ? e.replace(/.$/, "*") : e.substring(0, 3) + e.substring(3).replace(/./g, "*"), ze = (e, r) => {
  if (!r)
    return 0;
  const t = 1 - e / r;
  return Math.floor((t + Number.EPSILON) * 100);
};
export {
  oe as ACCESS_TOKEN_KEY,
  re as ADD_PWA_REQUESTED_KEY,
  q as ADULT_EXCLUDE_KEY,
  Q as ADULT_INCLUDE_KEY,
  ae as AUTO_LOGIN_KEY,
  ke as AVAILABLE_SCHEMES,
  J as DEAUTH_WATCHDOG_SERVER_TOKEN_KEY,
  Oe as DEVICE_TYPE_ANDROID,
  ye as DEVICE_TYPE_ANDROID_TABLET,
  Ye as DEVICE_TYPE_ETC_MOBILE,
  ge as DEVICE_TYPE_IPAD,
  Ce as DEVICE_TYPE_IPHONE,
  Te as DEVICE_TYPE_IPOD,
  se as DEVICE_TYPE_KEY,
  Ne as DEVICE_TYPE_PAPER,
  be as DEVICE_TYPE_PAPER_LITE,
  Le as DEVICE_TYPE_PAPER_PRO,
  ve as DEVICE_TYPE_PC,
  Ue as DEVICE_TYPE_RIDIPAPER,
  Ke as DEVICE_TYPE_RIDIPAPER4,
  xe as DEVICE_TYPE_WINDOWS_CE,
  we as DEVICE_TYPE_WINDOWS_PHONE,
  te as FINGERPRINT_KEY,
  ee as FROM_ACCOUNT_MODIFY,
  Ae as INPAY,
  X as LOGIN_TIME_KEY,
  le as LOGOUT_TIME_KEY,
  ie as NAMESPACE,
  ue as PHP_SESSION_KEY,
  Se as RDT_INFO,
  Re as REFERRAL_CODE,
  u as REFERRAL_COOKIE_KEY,
  E as REFERRAL_QUERY_KEY,
  ce as REFRESH_TOKEN_KEY,
  ne as REVIEW_TAB_KEY,
  me as RIDI_APP_APPSFLYER_ID,
  he as RIDI_APP_FIREBASE_APP_ID,
  De as RIDI_APP_GA_SESSION_ID,
  pe as RIDI_APP_INSTANCE_ID,
  Ee as RIDI_APP_KEY,
  B as RIDI_APP_THEME_KEY,
  _e as RIDI_APP_VERSION_KEY,
  W as RIDI_AUTH_INFO_KEY,
  de as RIDI_FFID_KEY,
  G as RIDI_NAVIGATION_KEY,
  Pe as RUID,
  j as ReviewTab,
  C as SCHEME_RIDI,
  y as SCHEME_RIDIAPPSTORE,
  O as SCHEME_RIDIONESTORE,
  g as SCHEME_RIDIPLAYSTORE,
  v as SCHEME_SAUDI,
  fe as SERVER_METRICS,
  Ie as STORAGE_PREFIX,
  Z as USE_GRID_BOOK_LISTING,
  N as authorRoleTexts,
  ze as getDiscountPercentage,
  Me as getParsedCookie,
  He as isNonNullable,
  je as maskingUserId,
  $e as setCookie,
  Fe as sortAuthors,
  Y as syncReferralCode,
  Ve as useSyncReferralCode
};
