import { SCHEME_SAUDI } from '@ridi-web/common';

import type { Config } from '../types';

const config: Config = {
  runMode: 'stage',
  allowedDomains: ['ridi.com', 'ridibooks.com'],
  domain: 'ridibooks.com',
  ridiComDomain: 'ridi.com',

  endpoints: {
    webViewer: {
      default: 'https://view.ridibooks.com',
    },
    ezwel: {
      default: 'https://openapi.ezwel.com',
    },
  },

  thirdParties: {
    saudi: {
      clientId: 'eCLa1eeYHK8cZNAgjd8unhPAMd5jowPwN8wPPMse',
      redirectUrl: `${SCHEME_SAUDI}://home`,
    },
    ezwel: {
      clientId: 'J3751luvGnmMnuAEzhr5WFPzGMk4wA2LmyYtPnVA',
      redirectUrl: 'https://ridibooks.com/account/connect/success',
    },
    naver: {
      clientId: 'teTTFk7JhxPI5TxREu28',
    },
    kakao: {
      restApiKey: '52e4fb87d417ee59f68b95948a943a9e',
      javaScriptKey: '5fecd8f4eab7ae9108879d32b1cb7d14',
    },
    apple: {
      clientId: 'com.initialcoms.BOM.service.login',
    },
    google: {
      clientId: '78321583520-esfoie314n4cf4esm97tnd1a184cn6b4.apps.googleusercontent.com',
    },
  },
};

export default config;
